<template>
  <div>
    <div class="container">
      <!-- header -->
      <div class="header">
        <BaseHeader
          title="Plano estratégico"
          :navigation="isMobile ? navigationMobile : navigation"
        ></BaseHeader>
        <img
          width="20px"
          height="20px"
          src="@/assets/img/icons/help-circle.svg"
          alt="icon"
          v-b-tooltip.hover.html="tipMethod"
        />
      </div>

      <div class="simulator mb-5" v-if="!loading">
        <div>
          <!-- <p class="title">Marketing</p> -->
          <div class="container-all-marketing">
            <!-- Quantos quero faturar ?  -->
            <div class="container-marketing">
              <div class="container-label">
                <span id="investment" class="name">Quanto quer faturar?</span>
                <span
                  v-show="!show_profit"
                  id="investment"
                  class="value"
                  @click="(show_profit = true), validShow('profit')"
                  >R$ {{ profit | formatMoney }}</span
                >
                <input
                  v-show="show_profit"
                  id="profit"
                  v-model="profit"
                  class="reset-money"
                  @blur="show_profit = false"
                  @input="updateSlider2('profit2', 500000)"
                  min="0"
                  max="500000"
                  type="number"
                />
              </div>
              <input
                ref="profit2"
                id="custom-range"
                type="range"
                min="0"
                max="500000"
                data-max="500000"
                v-model="profit"
                @input="updateSlider($event, 500000)"
              />
            </div>
            <!-- Preço do produto  -->
            <div class="container-marketing">
              <div class="container-label">
                <span id="investment" class="name">Preço do produto</span>
                <span
                  v-show="!show_product"
                  id="investment"
                  class="value"
                  @click="(show_product = true), validShow('product')"
                  >R$ {{ product | formatMoney }}</span
                >
                <input
                  v-show="show_product"
                  id="product"
                  v-model="product"
                  class="reset-money"
                  @blur="show_product = false"
                  @input="updateSlider2('product2', 15000)"
                  min="0"
                  max="15000"
                  type="number"
                />
              </div>
              <input
                ref="product2"
                id="custom-range"
                type="range"
                min="0"
                max="15000"
                data-max="15000"
                v-model="product"
                @input="updateSlider($event, 15000)"
              />
            </div>
            <!-- A cada quantos leads sai uma venda?  -->
            <div class="container-marketing">
              <div class="container-label">
                <span id="investment" class="name"
                  >A cada quantos leads sai uma venda?</span
                >
                <span
                  v-show="!show_leads"
                  id="investment"
                  class="value"
                  @click="(show_leads = true), validShow('leads')"
                  >{{ leads | formatNumber }}</span
                >
                <input
                  v-show="show_leads"
                  id="leads"
                  v-model="leads"
                  class="reset-money"
                  @blur="show_leads = false"
                  @input="updateSlider2('leads2', 50)"
                  min="1"
                  max="50"
                  type="number"
                />
              </div>
              <input
                ref="leads2"
                id="custom-range"
                type="range"
                min="1"
                max="50"
                data-max="50"
                v-model="leads"
                @input="updateSlider($event, 50)"
              />
            </div>
            <!-- Qual é o preço médio do lead?   -->
            <div class="container-marketing">
              <div class="container-label">
                <span id="investment" class="name"
                  >Qual é o preço médio do lead?
                </span>
                <span
                  v-show="!show_lead_value"
                  id="investment"
                  class="value"
                  @click="(show_lead_value = true), validShow('lead_value')"
                  >R$ {{ lead_value | formatMoney }}</span
                >
                <input
                  v-show="show_lead_value"
                  id="lead_value"
                  v-model="lead_value"
                  class="reset-money"
                  step="0.01"
                  @blur="show_lead_value = false"
                  @input="updateSlider2('lead_value2', 100)"
                  min="0"
                  max="100"
                  type="number"
                />
              </div>
              <input
                ref="lead_value2"
                id="custom-range"
                type="range"
                min="0"
                max="100"
                step="0.01"
                data-max="100"
                v-model="lead_value"
                @input="updateSlider($event, 100)"
              />
            </div>

            <!-- Conversão -->
            <div class="container-marketing off" style="padding: 23.33px">
              <div class="container-label">
                <span id="investment" class="off">Conversão</span>
                <span id="investment" class="off"
                  >{{
                    ((profit / product / (leads * (profit / product))) * 100)
                      | formatNumber
                  }}%</span
                >
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- <p class="title">Vendas</p> -->
          <div class="container-all-marketing">
            <!-- LUCRO  -->
            <div class="container-marketing total">
              <div class="container-label">
                <div
                  style="
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    width: 100%;
                  "
                >
                  <img src="@/assets/img/icons/money.svg" />
                  <span id="investment" class="lucro">Lucro</span>
                  <span
                    id="investment"
                    ref="profit"
                    class="value"
                    style="
                      cursor: default;
                      display: flex;
                      align-items: end;
                      justify-content: end;
                      width: 100%;
                    "
                    >R$ {{ calcProfit() | formatMoney }}</span
                  >
                </div>
              </div>
            </div>
            <!-- Quantas vendas preciso fazer? -->
            <div class="container-marketing off" style="padding: 23.33px">
              <div class="container-label">
                <span id="investment" class="off"
                  >Quantas vendas preciso fazer?</span
                >
                <span id="investment" class="off">{{
                  (profit / product) | formatNumber
                }}</span>
              </div>
            </div>
            <!-- Quantos leads preciso captar? -->
            <div class="container-marketing off" style="padding: 23.33px">
              <div class="container-label">
                <span id="investment" class="off"
                  >Quantos leads preciso captar?</span
                >
                <span id="investment" class="off">{{
                  (leads * (profit / product)) | formatNumber
                }}</span>
              </div>
            </div>

            <!-- Quantos vou ter que investir?   -->
            <div class="container-marketing off">
              <div class="container-label">
                <span id="investment" class="off"
                  >Quantos vou ter que investir?
                </span>
                <span id="investment" class="off"
                  >R$
                  {{
                    (leads * (profit / product) * lead_value) | formatMoney
                  }}</span
                >
              </div>
            </div>
            <!-- ROI   -->
            <div class="container-marketing off">
              <div class="container-label">
                <span id="investment" class="off">ROI </span>
                <span id="investment" class="off">{{
                  calcRoi() | formatMoney
                }}</span>
              </div>
            </div>
            <BaseButton
              variant="primary"
              :disabled="loading"
              @click="saveSimulator"
              v-if="can_save"
            >
              Salvar plano de crescimento
            </BaseButton>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <div class="validBtn"></div>
      <modalSimulator
        ref="Modal-simulator"
        :simulator_data="simulator_data"
        :simulation_edit="simulation_edit"
        :isMobile="isMobile"
      />
    </div>
  </div>
</template>

<script>
import BaseView from "@/template/BaseView.vue";
import { Money } from "v-money";
import modalSimulator from "@/components/Simulador/ModalSimulator";
//
import PlanService from "@/services/resources/PlanService";
const servicePlan = PlanService.build();
export default {
  components: {
    BaseView,
    Money,
    modalSimulator,
  },
  data() {
    return {
      navigation: [
        { link: "Estratégico", to: "/dynamicRoute/estrategico" },
        { link: "Gestão de planos", to: "/dynamicRoute/estrategico" },
        { link: "Plano estratégico", to: this.$route.fullPath },
      ],
      navigationMobile: [
        { link: "Estratégico", to: "/dynamicRoute/estrategico" },
        { link: "Gestão", to: "/dynamicRoute/estrategico" },
        { link: "Plano", to: this.$route.fullPath },
      ],
      simulation_edit: {},
      // shows -->
      show_lead_value: false,
      show_leads: false,
      show_product: false,
      show_profit: false,
      // datas -->
      lead_value: 8.0,
      leads: 10,
      product: 3500,
      profit: 10000,
      //
      simulator_data: {},
      loading: false,
      client: {
        width: 0,
      },
      all_plans: {},
      can_save: true,
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  filters: {
    formatMoney(number) {
      if (typeof number !== "undefined" && number && number != 0) {
        number = parseFloat(number);
        const formato = {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          useGrouping: true,
        };
        return number.toLocaleString("pt-BR", formato);
      }
      return "0,00";
    },
    formatNumber(number) {
      if (typeof number !== "undefined" && number) {
        number = parseFloat(number);
        return number.toLocaleString("pt-BR", {
          maximumFractionDigits: 0,
          useGrouping: true,
        });
      }
      return "0";
    },
  },
  methods: {
    getMeta(key){
      return this.simulation_edit.metas.find(meta => meta.meta_key == key)?.meta_value
    },
    getSimulator(id) {
      servicePlan
        .read(id)
        .then((resp) => {
          this.simulation_edit = resp;
          // Leads
          this.leads = this.getMeta('leads');
          // Imposto Producao
          this.imposto_producao = this.getMeta('imposto_producao');
          // Preço do produto
          this.lead_value = this.getMeta('cpl');
          // Investimento
          this.profit = this.getMeta('investimento');
          // Venda
          this.product = this.getMeta('venda');
        })
        .finally(() => {
          var inputs = document.querySelectorAll("#custom-range");
          for (let i = 0; i < inputs.length; i++) {
            const element = inputs[i];
            const max = element.dataset.max;
            const progress = (element.value / max) * 100;
            const gradient = `linear-gradient(to right, var(--greenn) ${progress}%, #E5E5E5 ${progress}%)`;
            element.style.background = gradient;
          }
          this.loading = false;
        });
    },
    tipMethod() {
      return (
        "<div style='display:flex;align-items: start;text-align:start'>" +
        "<span style='color:#fff'>Necessário para preencher este planejamento:<span><br>" +
        "<span style='color:#fff'>1 - Ter em mente quanto quer faturar no mês ou no lançamento,<span><br>" +
        "<span style='color:#fff'>2 - Preço do produto,<span><br>" +
        "<span style='color:#fff'> 3 - Saber quantos leads precisa pra fazer uma venda; (Essa informação é com base em seu histórico de vendas, se não tiver, vai precisa chutar uma quantidade) --> 30,<span><br>" +
        "<span style='color:#fff'> 4 - Saber qual o seu CPL médio ou qual quer atingir; (Essa informação é com base em seu histórico de captação, se não tiver, coloque um valor médio de mercado) --> 5,00<span><br>" +
        "</div>"
      );
    },
    canSave() {
      if (this.$store.getters.recursos.nstrategic) {
        this.can_save = true;
      }
    },
    calcProfit() {
      var i9 = this.leads * (this.profit / this.product) * this.lead_value;
      var result = this.profit - i9;
      return result;
    },
    calcRoi() {
      var i9 = this.leads * (this.profit / this.product) * this.lead_value;
      var calc1 = this.profit - i9;
      var result = calc1 / i9;
      return result;
    },
    updateSlider2(name, value) {
      var element = this.$refs[`${name}`];
      var rangeValue;
      if (name == "lead_value2") {
        rangeValue = this.lead_value;
      } else if (name == "leads2") {
        rangeValue = this.leads;
      } else if (name == "product2") {
        rangeValue = this.product;
      } else if (name == "profit2") {
        rangeValue = this.profit;
      }

      const progress = (rangeValue / value) * 100;
      const gradient = `linear-gradient(to right, var(--greenn) ${progress}%, #E5E5E5 ${progress}%)`;

      element.style.background = gradient;
    },
    updateSlider(event, value) {
      const rangeValue = event.target.value;
      const progress = (rangeValue / value) * 100;
      const gradient = `linear-gradient(to right, var(--greenn) ${progress}%, #E5E5E5 ${progress}%)`;

      event.target.style.background = gradient;
    },
    validShow(name) {
      var input = document.getElementById(`${name}`);
      this.$nextTick(() => input.focus());
    },
    saveSimulator() {
      var profit = this.$refs.profit.innerText.replace("R$ ", "");
      profit = profit
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(",", ".");
      var data = {
        cliques: 0,
        taxa_cartao: 0,
        cpc: "v2",
        // v2 -->
        investimento: this.profit,
        venda: this.product,
        leads: this.leads,
        imposto_producao: this.lead_value,
        total_profit: profit,
        cpl: this.lead_value,
        cpv: this.profit / this.product,
      };
      this.simulator_data = data;
      this.$grModal.show("Modal-simulator", this);
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  mounted() {
    var inputs = document.querySelectorAll("#custom-range");
    for (let i = 0; i < inputs.length; i++) {
      const element = inputs[i];
      const max = element.dataset.max;
      const progress = (element.value / max) * 100;
      const gradient = `linear-gradient(to right, var(--greenn) ${progress}%, #E5E5E5 ${progress}%)`;
      element.style.background = gradient;
    }
    if (this.$route.params.id) {
      this.getSimulator(this.$route.params.id);
    }
  },
  created() {
    if (
      this.$store.getters.user.user.level != "commercial_leader" &&
      this.$store.getters.user.user.level != "admin" &&
      this.$store.getters.user.user.level != "owner"
    ) {
      this.$router.push("/dynamicRoute/AccessDenied");
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.canSave();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.reset-money {
  width: 40% !important;
  margin-bottom: 0px !important;
  height: auto !important;
  padding: 5px 20px !important;
}
.total {
  background: var(--greenn2);
  .lucro {
    color: var(--greenn);
    font-size: 16px;
    font-weight: 600;
  }
}
.off {
  background: #f7f7f7;
}
.container-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .name {
    font-weight: 600;
    font-size: 16px;
  }
  .value {
    color: var(--greenn);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  .off {
    color: #81858e;
    font-weight: 600;
    cursor: default;
  }

  @media (max-width: 768px) {
    .value {
      font-size: 14px;
    }
    .off {
      font-size: 14px;
    }
  }

  img {
    width: 33px;
    height: 33px;
  }
}
.container-marketing {
  display: grid;
  gap: 10px;
  // padding: 20px;
  height: 78px;
  padding: 0 20px;
  align-content: center;
  border-radius: 7px;
  border: 1px solid #e5e5e5;
}
.container-all-marketing {
  display: grid;
  gap: 15px;
}
.simulator {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  .title {
    font-size: 22px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      width: 100%;
    }
  }
}
input[type="range"] {
  border: none !important;
  padding: 0px !important;
  height: auto !important;
  /*   -webkit-appearance: none; */
  appearance: none !important;
  position: relative !important;
  width: 100% !important;
  cursor: pointer !important;
  height: 4px !important;
  border-radius: 15px !important;
  outline: none !important;
  margin: 0 !important;
}
//  Thumb: webkit
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  height: 10px !important;
  width: 10px !important;
  background-color: var(--greenn) !important;
  border-radius: 50% !important;
  border: 2px solid var(--greenn) !important;
  transition: 0.2s ease-in-out !important;
}
/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 10px !important;
  width: 10px !important;
  background-color: var(--greenn) !important;
  border-radius: 50% !important;
  border: 2px solid var(--greenn) !important;
  transition: 0.2s ease-in-out !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
